import _ from "lodash";
import React, { forwardRef, useEffect, useState } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    ZoomableGroup
} from "react-simple-maps";
import { motion } from "framer-motion";

import northAmericaGeoData from "../../content/north-america2.json";

export default function ProjectMiniMap(props) {

    const [show, setShow] = useState(false);

    useEffect(() => {

        setShow(true);

        return () => {
            setShow(false);
        };
    });

    return (
        <motion.div
            style={{
                height: '5vw',
                width: '5vw',
                margin: '0.5vw auto'
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <ComposableMap
                width={64}
                height={64}
                projection="geoAlbers"
                projectionConfig={{
                    scale: 333
                }}
            >   
                <ZoomableGroup center={[props.project.lng, props.project.lat]}>
                    <Geographies geography={northAmericaGeoData}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill="#3a383a"
                                    stroke="#c7c8ca"
                                />
                            ))
                        }
                    </Geographies>

                    <Marker
                        key={"Marker"}
                        coordinates={[
                            props.project.lng,
                            props.project.lat
                        ]}
                    >

                        <circle
                            r={3}
                            fill="#f9544d"
                        />

                    </Marker>
                </ZoomableGroup>
            </ComposableMap>
        </motion.div>
    );
}
