import _ from "lodash";
import { useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useLocation, useHistory  } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"

import "bootstrap/dist/css/bootstrap.min.css";
import "video-react/dist/video-react.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

import "./css/main.scss";

import Intro from "./components/intro";
import Navigation from "./components/navigation";
import Category from "./components/category";
import Project from "./components/project";
import { useState } from "react";

global._ = _;

export default function App() {

    const history = useHistory();
    const location = useLocation();
    const [category, setCategory] = useState();
    const [project, setProject] = useState();
    const [env, setEnv] = useState('PROD');
    const timer = useRef()

    useEffect(() => {
        if (location.pathname !== '/') {
            timer.current = setTimeout(() => {
                history.push('/')
            }, 60000 * 5)
        }
        return () => {
            clearTimeout(timer.current)
        }
    }, [location, history])
    
    useEffect(() => {
        setCategory(location.pathname.split("/category/")[1] ?? location.pathname.split("/categories/")[1]?.split("/")[0]);
        setProject(location.pathname.split("/categories/")[1]?.split("/projects/")[1]);
    }, [location]);

    useEffect(() => {
        (async () => {
            try {
                await fetch("http://localhost:9090/handshake", { mode: "no-cors" });
                setEnv("PROD");
            } catch (err) {
                setEnv("DEV");
                //console.error(err);
            }
        })();
    }, []);

    return (
        <>
            <AnimatePresence exitBeforeEnter={true}>

                <Switch location={location} key={location.pathname}>

                    <Route path={"/intro"}>

                        <Intro />

                    </Route>

                    <Route path={"/categories/:category_id/projects/:project_id"}>

                        <Project />

                    </Route>

                    <Route path={"/category/:category_id"}>

                        <Category env={env} />

                    </Route>

                    <Route path={"/"}>

                        <Intro />

                    </Route>

                    <Route path={"*"}>

                        <h1>test</h1>

                    </Route>

                </Switch>

            </AnimatePresence>

            {category &&
                <Navigation category_id={category} project_id={project} />
            }

        </>
    );
}
