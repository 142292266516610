import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
//import { ControlBar, Player } from "video-react";
import Video from "./Video";

import ScrollContainer from "./scrollContainer";

import siteData from "../content/site_data.json";

export default function Category({env}) {

    let { category_id } = useParams();
    //let playerRef = null;

    const category = _.find(siteData.categories, { id: category_id });
    const content = category.content;

    // const [showContent, setShowContent] = useState(false);
    // const [videoEnded, setVideoEnded] = useState(false);
    const [player, setPlayer] = useState(null);
    const [showPrompt, setShowPrompt] = useState(false);

    /*useEffect(() => {
        if (playerRef) {
            playerRef.subscribeToStateChange(handleBind);
        }
    });*/

    /*function handleBind(state) {

        // setstate(state);
        // console.log(state);

        if (state.ended === true) {
            setShowPrompt(true);
        }
    }*/

    const renderContent = () => {

        return content.main.map((item, index) => {
            return <motion.p
                className={"text-gray"}
                key={`${index}`}
            >
                {item.content}
            </motion.p>;
        });
    };

    const dispose = () => {
		player.dispose();
	}

    const pauseVideo = () => {
		if(player){
			player.pause();
		    setTimeout(() => {
			    dispose();
            }, 650);
		}
	}

    const playerReady = (player) => {
		
		setPlayer(player);
		
		player.on('canplay', () => {
			player.play();
		});

        player.on('ended', () => {
            setShowPrompt(true);
        });

		/*player.on('dispose', () => {
			console.log('player dispose');
		});*/
	}

    const onAnimStart = () => {
        pauseVideo();
    };

    // const onAnimComplete = () => {
    //
    //     setShowContent(true);
    //
    //     console.log("CAT", category);
    //
    //     if (category.video_url) {
    //         let video = document.getElementById("video");
    //         if (video.paused && !videoEnded) {
    //             video.play();
    //         }
    //     }
    // };

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    };

    return (
        <motion.div className="App d-flex flex-column flex-grow-1 bg-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // onAnimationComplete={onAnimComplete}
            onAnimationStart={onAnimStart}
        >

            <Container fluid className={"flex-grow-1"}>
                <Row className={"main-row h-100"}>

                    <Col
                        className={"bg-dark-gray h-100 column-left"}
                    >

                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                            >

                                <motion.h2 className={"text-yellow mb-4"} variants={item}>
                                    {content.heading}
                                </motion.h2>

                                <ScrollContainer
                                    className={"h-100"}
                                    style={{
                                        height: 400
                                    }}
                                >

                                    <motion.div variants={item}>
                                        {renderContent()}
                                    </motion.div>

                                </ScrollContainer>

                                <If condition={content.prompt}>

                                    <motion.p className={"prompt text-white"} variants={item}>
                                        {content.prompt}
                                    </motion.p>

                                </If>

                            </motion.div>

                    </Col>

                    <Choose>
                        <When condition={category.id === "all-markets"}>

                            <Col className={"bg-black map-container-static"}>
                                <img src="/images/map-no-background.svg" width="2678" height="1836" alt="" />
                            </Col>

                        </When>
                        <Otherwise>

                            <Col className={"bg-black video-container"}>

                                <If condition={showPrompt}>

                                    <motion.div className="video-prompt-wrapper" variants={item}>
                                        <motion.div className="video-prompt" variants={item}>
                                            Explore our signature projects below
                                        </motion.div>
                                    </motion.div>

                                </If>

                                {/* <Player
                                    ref={(player) => {
                                        playerRef = player;
                                    }}
                                    autoPlay={true}
                                    muted={true}
                                    src={category.video_url}
                                    loop={false}
                                >
                                    <ControlBar
                                        disableCompletely={true}
                                    />

                                </Player> */}

                                <Video onReady={playerReady} options={{
									sources: [{
										src: env === 'PROD' ? `http://localhost:9090${category.video_url}` : `https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/d-project-showcase/1080/${category.video_url.split('/')[category.video_url.split('/').length-1]}`,
										type: 'video/mp4'
									}]
								}} />

                            </Col>

                        </Otherwise>
                    </Choose>

                </Row>

            </Container>

        </motion.div>
    );
}
